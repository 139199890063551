import React, { useState } from "react";
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from "@components/kaigai-fx/layout";
import FixedFoot from "@components/kaigai-fx/fixedFooter.js";
// import parse from "html-react-parser"
import LazyLoad from 'react-lazy-load';

// 関数呼び出し
import { SeoContents } from "@components/kaigai-fx/seo-data.js" // Seo情報設定
import Animation from "@components/kaigai-fx/animation.js";
import { BannerDataComponent } from "@components/kaigai-fx/bannerDataComponent.js";
import Slider from "@components/kaigai-fx/slider.js";
import { SearchCategList } from "@components/kaigai-fx/search-categList.js"; // カテゴリ検索機能
import { WordSearch } from "@components/kaigai-fx/word-search.js";
import { ProfileContents } from "@components/kaigai-fx/profile.js";
import { RelatedContents } from "@components/kaigai-fx/related-article.js"; // 関連記事取得・形成

// Import SVG
import icon_t_SVG1 from '@images/kaigai-fx/top_t_icon1.svg';
import icon_post_SVG1 from '@images/kaigai-fx/post_icon1.svg';
import icon_post_SVG2 from '@images/kaigai-fx/post_icon2.svg';


const FxIndex = ({pageContext, data}) => {

  // console.log(pageContext)

  // 基本設定
  const indexUrl = '/kaigai-fx/';
  
  // 本文情報
  const topPostData = data.topPostData.edges[0];
  const name = topPostData.node.title; // 該当記事名

  // Seo情報設定
  const pathName = 'index';
  const seoTag = SeoContents({ Path: pathName, Title: topPostData.node.title, Slug: '', Description: topPostData.node.excerpt, Date: topPostData.node.date, Modified: topPostData.node.modified });

  // バナー情報取得（初回レンダリング時のみ作動）
  const getBannerData = data.imagesettingData.edges.find(item => item.node.slug === 'banner');
  const getArchiveVisual = data.imagesettingData.edges.find(item => item.node.slug === 'archive-visual');
  const [bannerArray] = useState(() => BannerDataComponent('banner', 'banner_area_1', getBannerData));
  const [bannerArray_side] = useState(() => BannerDataComponent('banner', 'banner_area_3', getBannerData));
  
  // カテゴリ検索リスト(components/search-categList.js)
  const searchList = SearchCategList().Main;
  
  // ワード検索
  const wordSearchContentPC = WordSearch().PC;
  const wordSearchContentMB = WordSearch().MB;

  // プロフィール
  const profilePC = ProfileContents().PC 
  const profileMB = ProfileContents().MB

  // 関連記事取得(components/related-article.js)
  const postArray = {slug: topPostData.node.slug}
  const tagArray = topPostData.node.fxTags.nodes.filter(item => item.name.indexOf('Q&A') === -1); // Q&Aタグ削除
  const [relatedList] = useState(() => RelatedContents( postArray, tagArray, [topPostData.node.slug], getArchiveVisual, 4));
  // console.log(relatedList)

  // 日時形式変換用関数
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const monthIndex = date.getMonth();
    const day = date.getDate();
    return `${year}年${monthIndex + 1}月${day}日`;
  }

  // 新着情報 ---------------------------------------------------------
  const setNum = 3; // 表示するデータ数の設定
  const dateArray = data.dateData.edges;
  const modifiedArray = data.modifiedData.edges;
  let mixArray = [];
  // データのマージと属性の追加
  modifiedArray.forEach((result) => {
    mixArray.push({...result,
      node: {
        ...result.node,
        mixdate: result.node.modified,
        word: 'が更新されました。'
      }
    });
  });
  dateArray.forEach((result) => {
    mixArray.push({...result,
      node: {
        ...result.node,
        mixdate: result.node.date,
        word: 'が作成されました。'
      }
    });
  });
  // 日付でソート
  mixArray.sort((a, b) => Date.parse(b.node.mixdate) - Date.parse(a.node.mixdate));
  // 重複データの削除
  const seenSlugs = new Set();
  const listData = mixArray.filter(item => {
    const isDuplicate = seenSlugs.has(item.node.slug);
    seenSlugs.add(item.node.slug);
    return !isDuplicate;
  }).slice(0, setNum); // 最初の3つの要素を取得

  return (
  <>
    <Layout>
      {seoTag}
      
      {/* バナー画像 */}
      <div className="mx-auto max-w-[1920px] mb-2 top_slider">
        {bannerArray? <Slider imageArray={bannerArray}/>: <></>}
      </div>

      <div className="max-w-xl mx-auto md:max-w-6xl px-5 pt-2 md:pt-0">

        {/* TOP固定記事表示 */}
        <div className="md:max-w-6xl md:flex justify-between mx-auto md:pt-12"> 
          {/*1 column */}
          <div className="max-w-3xl pb-5 px-5 mb-10 md:pb-0 md:mb-0 blog-post">
            
            {/* 日付 */}
            <div className="mt-3 text-[12px] flex items-center mb-3">
              <LazyLoad className='' width={13} offset={300}>
                <img className="h-auto" width="18.72" height="18.72" alt="icon2" src={icon_post_SVG2}/>
              </LazyLoad>
              <span className="px-2"><time>{formatDate(topPostData.node.date)}</time></span>
              <LazyLoad className='flex items-center' height={18.72} width={18.72} offset={300}>
                <img className="pl-2 h-auto" width="18.72" height="18.72" alt="icon1" src={icon_post_SVG1}/>
              </LazyLoad>
              <span className="px-2"><time>{formatDate(topPostData.node.modified)}</time></span>
            </div>

            <h1 className="md:text-xl text-base font-semibold tracking-wide" itemProp="headline">{ name }</h1>

            {/* description */}
            {/* <div className="mt-[15px] text-base">{parse(topPostData.node.excerpt)}</div> */}
            <div className="mt-[15px] text-base" dangerouslySetInnerHTML={{ __html: topPostData.node.excerpt }} />
            
            {/* 本文 */}
            <div className="md:my-0 md:mx-auto" style={{ paddingTop: 0, paddingBottom: 24, maxWidth: 976}}>
              {!!topPostData.node.content && (
                // <section itemProp="articleBody">{parse(pageContext.contentsData)}</section>
                <section itemProp="articleBody" dangerouslySetInnerHTML={{ __html: pageContext.contentsData }}/>
              )}
            </div>
            
          </div>

          {/*2 column */}
          <aside className="column2_filed px-5 md:pt-0 pt-8">
            <div className="toc_mt">

              {/* バナー画像（横）*/}
              {bannerArray_side?.map((result) => (
                <a className="block md:mt-0 mt-5 mb-5" href={result.url} aria-label="banner" {...result.linktype !== '内部リンク' ? {target: '_blank', rel: 'noopener noreferrer'}: {}}>
                  <span className="hidden">{result.text}</span>
                  <GatsbyImage
                    image={getImage(result.image?.localFile?.childImageSharp?.gatsbyImageData)}
                    alt={result.title}
                  />
                </a>
              ))}

              {/*関連記事 */}
              <h3 className="mt-13 md:mt-8 mb-5 font-semibold flex">関連記事</h3>
              <div className="flex-wrap justify-between mb-10 md:block flex">
                {relatedList}
              </div>
              <div className="mb-10">
                {/*カテゴリ検索（PC） */}
                {searchList}
                {/*PCワード検索 */}
                {wordSearchContentPC}
              </div>
              {/* PCプロフィール */}
              {profilePC}
            </div>
          </aside>
        </div>

        <div>
          {/*ワード検索 */}
          {wordSearchContentMB}
          {/* MBプロフィール */}
          {profileMB}
        </div>
      </div>

      <div className="bg-[#F2F2F0]">
        <Animation customType='falling-item'>
          <div className="max-w-xl mx-auto md:max-w-6xl px-5 pb-5 pt-5 md:pt-8"> 
            {/* 新着情報 */}   
            <div className="text-left mt-[30px] md:mt-[50px]">
              <LazyLoad className="!inline" height={59.84} width={38} offset={300}>
                <img className="mb-5 h-auto inline mr-3" alt="icon1" width="38px" src={icon_t_SVG1}/>
              </LazyLoad>
              <h2 className="font-semibold tracking-wide text-base md:text-[24px] inline">新着情報</h2>
              <dl className="mt-10 md:mb-[110px] mb-[50px]">
                {listData.map((result) => 
                  <>
                    <dt className="font-semibold text-[12px] mb-2">{result.node.mixdate}</dt>
                    <dd className="block mb-4 border-b-[1px] border-dotted pb-3 border-slate-400">
                      <a href={`${indexUrl}${result.node.slug}/`} className="hover:text-gray-400">{`「${result.node.title}」${result.node.word}`}</a>
                    </dd>
                  </>
                )}
              </dl>
            </div>
          </div>
        </Animation>
      </div>

      {/* 固定フッター */}
      {/* {fixedItem} */}
      <FixedFoot fixPattern="nomal" item="" />
    </Layout> 
  </>
  )
}

export const query = graphql`
  query indexquery {
    modifiedData: allWpKaigaiFx(
      filter: {fxTags: {nodes: {elemMatch: {slug: {nin: "display-on-top"}}}}}
      limit: 5
      sort: {fields: modified, order: DESC}
    ) {
      edges {
        node {
          title
          slug
          modified(formatString: "YYYY/MM/DD")
          date(formatString: "YYYY/MM/DD")
        }
      }
    }
    dateData: allWpKaigaiFx(
      filter: {fxTags: {nodes: {elemMatch: {slug: {nin: "display-on-top"}}}}}
      limit: 5
      sort: {fields: date, order: DESC}
    ) {
      edges {
        node {
          title
          slug
          modified(formatString: "YYYY/MM/DD")
          date(formatString: "YYYY/MM/DD")
        }
      }
    }
    topPostData: allWpKaigaiFx(
      filter: {fxTags: {nodes: {elemMatch: {slug: {in: "display-on-top"}}}}}
      sort: {fields: modified, order: DESC}
    ) {
      edges {
        node {
          title
          slug
          date
          modified
          excerpt
          content
          fxTags {
            nodes {
              name
              slug
              wpParent {
                node {
                  slug
                  name
                }
              }
            }
          }
        }
      }
    }
    linkSettingData: allWpLinksettings(sort: {fields: slug, order: ASC}) {
      edges {
        node {
          title
          slug
          linksettingdata {
            atozPageGroup {
              accountInformationPage {
                url
                variables
              }
              accountOpeningProcedurePage {
                url
                variables
              }
              basicInformationPage {
                url
                variables
              }
              bonusInformationPage {
                url
                variables
              }
            }
            officialPageGroup {
              accountOpeningPage {
                url
                variables
              }
              campaignPage {
                url
                variables
              }
              swapPointsPage {
                url
                variables
              }
              toppage {
                url
                variables
              }
            }
          }
        }
      }
    }
    imagesettingData: allWpImagesettings {
      edges {
        node {
          title
          slug
          smartCustomFields {
            broker_name
            archive_text1
            archive_text2
            text_position
            font_color
            image
          }
          imagesetting {
            bannerSettingsGroup {
              fieldGroupName
              bannerArea1 {
                fieldGroupName
                bannerA {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
                bannerB {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
                bannerC {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
              }
              bannerArea2 {
                fieldGroupName
                bannerA {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
                bannerB {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
                bannerC {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
              }
              bannerArea3 {
                fieldGroupName
                bannerA {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
                bannerB {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
                bannerC {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default FxIndex